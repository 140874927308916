<script lang="ts">
export default {
    emits: ['loadData'],
    data() {
        return {
            loading: false,
            saving: false,
            rules: null,
            meta: null,
            fetchedData: null,
            isRedirecting: false,

            isEditingMap: false, // from map children
            defaultUrlOverload: null,
            permissionNameOverload: null,
            translationNameOverload: null,
            currentKeyOverload: null,
            requestNameOverload: null,
        }
    },
    computed: {
        currentKey() {
            if (this.currentKeyOverload) {
                return this.currentKeyOverload
            }
            return this.$route.params.k !== 'nouveau' ? this.$route.params.k : null
        },
        isCreatePath() {
            return this.$route.params.k === 'nouveau'
        },
        defaultUrl() {
            return this.defaultUrlOverload || this.$attrs['default-url']
        },
        translationName() {
            return this.translationNameOverload || this.$attrs['translation-name']
        },
        titleModal() {
            const text = `${this.translationName}.${this.isForm ? this.$route.fullPath.includes('nouveau') ? 'add' : 'edit' : 'view'}`
            return this.$translate(text, { ...this.fetchedData })
        },
        requestName() {
            return this.requestNameOverload || this.$attrs['request-name']
        },
        permissionName() {
            return this.permissionNameOverload || this.$attrs['permission-name']
        },
        isForm() {
            return (
                (this.$permissionCheck(`${this.permissionName}_update`) && (this.$route.fullPath.includes('formulaire')))
                || (this.$permissionCheck(`${this.permissionName}_store`) && (this.$route.fullPath.includes('nouveau')))
            )
        },
    },
    watch: {
        $route(/*to, from*/) {
            // PREVENT TO CANCEL REDIRECTION TO ANOTHER PAGE WHEN LAUNCHED FROM A MODAL OPEN
            this.isRedirecting = true
            setTimeout(() => {
                this.isRedirecting = false
            }, 200);
        }
    },
    mounted() {
        // if (process.client && window) {
        //     window.history.scrollRestoration = 'auto';
        // }
        if (this.$refs.modalParent) {
            this.$refs.modalParent.showModal()
        }

        this.getFullData()
    },

    methods: {
        async getFullData() {
            if (this.requestName) {
                this.loading = true
                // console.log('currentKey', this.currentKey, this.requestName)
                const res = await this.$api.getData({
                    url: `${this.requestName}${this.currentKey || this.isCreatePath ? '/' : ''}${this.setupCreateRoute() || ''}`,
                })
                this.fetchedData = res.data
                this.meta = res.meta
                this.rules = res.meta && res.meta.rules || []
                this.formatRules()
                this.loading = false
            }
        },
        setupCreateRoute() {
            const queryKeys = Object.keys(this.$route.query)
            let path = this.currentKey !== null ? this.currentKey : 'create'
            if (queryKeys.length) {
                queryKeys.forEach((key, i) => {
                    path += i === 0 ? '?' : '&'
                    path += `${key}=${this.$route.query[key]}`
                })
            }

            return path
        },
        formatRules() {
            const keys = Object.keys(this.rules)
            keys.forEach(key => {
                this.rules[key] = this.rules[key].split("|")
            })
        },
        closeModal() {
            // this.$refs.modalParent.showModal()
            if (!this.isRedirecting) {
                this.$router.replace(this.defaultUrl)
            }
        },
        refreshTable() {
            this.saving = false
            if (this.$refs.modalParent) {
                this.$refs.modalParent.closeModal()
            }
            this.$router.replace(this.defaultUrl)
            this.$emit('loadData')
        },
        setEditingMap(value) {
            this.isEditingMap = value
            console.log('setEditingMap', this.isEditingMap, value)
        },
        async save(formData: FormData|array|null = null) {
            if (!(formData instanceof FormData) || !(formData instanceof Array)) {
                formData = null
            }
             
            this.saving = true
            // let v = this.$Trefs.display.pageRef.v$
            this.$refs.display.pageRef.v$.$touch()
            this.$refs.display.pageRef.v$.$validate()

            setTimeout(async () => {
                if (this.$refs.display.pageRef.v$.$error) {
                    this.saving = false
                    // this.$showToaster({ text : `${this.$refs.display.pageRef.v$.$errors.map( e => e.$uid).join(",")}`, color : "warning", icon : "mdi-alert-circle" })
                    this.$showToaster({ text: `Veuillez renseigner les champs obligatoires.`, color: 'warning', icon: 'mdi-alert-circle' })
                    this.$refs.modalParent?.scrollToTop()
                }
                else if (this.isEditingMap) {
                    this.saving = false
                    this.$showToaster({ text: `Veuillez terminer la modification de la carte en cliquant sur "Terminer" avant de valider le formulaire`, color: 'warning', icon: 'mdi-alert-circle' })
                    return
                }
                else {
                    try {
                        const documents = this.$refs.display.pageRef.documents
                        if (this.fetchedData.k) {
                            this.$api.setRequest({
                                url: `${this.requestName}/${this.fetchedData.k}`,
                                method: 'PUT',
                                translation: this.translationName,
                                body: formData ?? this.fetchedData,
                            }).then(async () => {
                                if (!formData) // no save documents when formData is set
                                    await this.$api.saveDocuments(documents, this.requestName, this.fetchedData.k, 'documents')
                                this.refreshTable()
                            }).catch(() => {
                                this.saving = false
                            })
                        }
                        else {
                            this.$api.setRequest({
                                url: `${this.requestName}`,
                                method: 'POST',
                                translation: this.translationName,
                                body: formData ?? this.fetchedData,
                            }).then(async (res) => {
                                if (!formData) // no save documents when formData is set
                                    await this.$api.saveDocuments(documents, this.requestName, res.data.k, 'documents')
                                this.refreshTable()
                            }).catch(() => {
                                this.saving = false
                            })
                        }
                    }
                    catch {
                        this.saving = false
                    }
                }
            }, 500)
        },
    },
}
</script>
